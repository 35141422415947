import { cloneElement, Fragment, type ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { type CompositeFilterDescriptor, filterBy, type FilterDescriptor } from '@progress/kendo-data-query';
import { DropDownList, type DropDownListFilterChangeEvent, type ListItemProps } from '@progress/kendo-react-dropdowns';

import { Icon } from '../../../shared/Icon/Icon';
import { BaseMultiSelect } from '../../../shared/Inputs';
import type { RootState } from '../../../../store/reducers/rootReducer';
import type { Tool, ToolInfo } from '../../../../interfaces/workflowInterfaces/toolInterfaces';

type Tags = {
  id: string;
  name: string;
}

interface Props {
  handleUpdateToolData: (type: string, id: string | boolean) => void
  editMode: boolean
  tags: Tags[]
  toolData: ToolInfo
  updatedWorkflowTools: Tool[]
  isFetchingCustomDataOps: boolean
  allTools: Tool[]
  setUpdatedWorkflowTools: (data: Tool[]) => void
}

export const WorkflowToolGeneralTabContent = ({ handleUpdateToolData, editMode, tags, toolData, updatedWorkflowTools, isFetchingCustomDataOps, allTools, setUpdatedWorkflowTools }: Props) => {
  const { workflowData } = useSelector((theState: RootState) => theState.workflowStateReducer);
  const [hasEditedName, setHasEditedName] = useState(false);

  const listNoDataRender = () => {
    return (
      <h5 className="text-muted text-center">
        <span className="k-icon k-i-warning my-3" style={{ fontSize: '2.5em' }} />
        <br />
        <span>No tags created yet</span>
      </h5>
    );
  }

  const autoPopulateName = (toolType: string, item: { id: string, text: string, isCustom?: boolean }) => {
    const { id, text, isCustom } = item;
    if (!hasEditedName) {
      handleUpdateToolData('name', `${text} (${workflowData.data.toolReferences.length + 1})`);
    }
    handleUpdateToolData('isCustom', typeof isCustom === 'boolean' ? isCustom : false);
    handleUpdateToolData(toolType, id);
  }

  const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
    return filterBy(allTools, filter);
  };

  const filterChange = (event: DropDownListFilterChangeEvent) => {
    setUpdatedWorkflowTools(filterData(event.filter));
  };

  const itemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = (
      <div className="d-flex justify-content-between flex-grow-1">
        <div className="d-flex align-items-center">
          <Icon type={itemProps.dataItem.icon} />
          <p className="mb-0 ml-3">{li.props.children as TODO}</p>
        </div>
        {
          itemProps.dataItem.isCustom &&
          <span style={{ lineHeight: 'unset' }} className="badge small font-weight-normal badge-light">Custom</span>
        }
      </div>
    );
    return cloneElement(li, li.props, itemChildren);
  };

  const valueRender = (element: React.ReactElement<HTMLSpanElement>, value: TODO) => {
    if (!value) return element;
    const children = [
      <Icon key={1} type={value.icon} />,
      <span key={2}>&nbsp; {element.props.children as TODO}</span>,
    ];
    return cloneElement(element, { ...element.props }, children);
  };

  return (
    <Fragment>
      <span className="mb-2">Type</span>
      <div className="d-flex flex-column survey-option">
        <DropDownList
          textField='text'
          filterable={true}
          data={updatedWorkflowTools}
          loading={isFetchingCustomDataOps}
          className='form-control d-flex bg-white shadow-none px-0 mb-3'
          defaultItem={{ text: 'Select tool type', id: '1', icon: 'single' }}
          value={updatedWorkflowTools.find(tool => editMode ? workflowData.selectedTool && tool.id === workflowData.selectedTool.toolType : tool.id === toolData.toolType)}
          itemRender={itemRender}
          valueRender={valueRender}
          onFilterChange={filterChange}
          onChange={(e) => autoPopulateName('toolType', e.value)}
        />
      </div>

      <span className="mb-2">Name</span>
      <input placeholder="Enter tool name" className="form-control mb-3" value={editMode ? workflowData.selectedTool?.name : toolData.name} onChange={(e) => { setHasEditedName(e.target.value !== ""); handleUpdateToolData('name', e.target.value) }} />

      <span className="mb-2">Tags</span>
      <BaseMultiSelect
        data={tags ? tags : []}
        value={editMode ? workflowData.selectedTool?.tags ? workflowData.selectedTool.tags : [] : toolData.tags ? toolData.tags : []}
        textField="name"
        dataItemKey="id"
        allowCustom={true}
        className="mb-3"
        listNoDataRender={listNoDataRender}
        onChange={(e: { target: { value: string; }; }) => handleUpdateToolData('tags', e.target.value)}
      />

      <span className="mb-2">Description</span>
      <textarea
        rows={3}
        name="description"
        className="form-control w-100 mb-3"
        placeholder="Enter tool description"
        value={editMode ? workflowData.selectedTool?.description : toolData.description}
        onChange={(e) => handleUpdateToolData('description', e.target.value)}
      />
    </Fragment>
  )
}