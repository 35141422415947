export const returnXtRowQuestionsForAnalysisBody = (question, lang, defaultLanguage) => {
  return {
    options: question.options ? question.options : {},
    id: question.title,
    subqIndex: question.subqIndex,
    isDisabled: false,
    summaryValuesOnly: question.summaryValuesOnly ? question.summaryValuesOnly : false,
    swapRowsCols: question.swapRowsCols ? question.swapRowsCols : false,
    suppressGridCols: question.subGroups?.filter(item => item.suppress).map((item) => {
      return item.code
    }),
    hierarchy: question.hierarchy?.map(element => {
      return {
        id: element.id,
        subqIndex: element.subqIndex,
        isDisabled: element.isDisabled,
        editedText: lang === defaultLanguage ? Array.isArray(question.content) ? question.content[0] : question.content : null,
        rows: element.rows?.filter(el => el.active).map(row => {
          return {
            code: row.code,
            editedText: lang === defaultLanguage ? row.text : null
          }
        }),
        cols: element.cols?.filter(el => el.active).map(col => {
          return {
            code: col.code,
            editedText: lang === defaultLanguage ? col.text : null
          }
        }),
      }
    }),
    // editedText: lang === "en" || lang === undefined ? Array.isArray(question.content) ? question.content[0] : question.content : null,
    editedText: lang === defaultLanguage ? Array.isArray(question.content) ? question.content[0] : question.content : null,
    rows: question.groups.filter(group => group.active).map((row, id) => {
      return {
        code: row.code,
        weightValue: typeof row.weightValue === 'number' ? row.weightValue : null,
        isDisabled: false,
        editedText: lang === defaultLanguage ? row.text : null
      }
    }),
    cols: question.subGroups?.filter(group => group.active).map((row, id) => {
      return {
        code: row.code,
        weightValue: typeof row.weightValue === 'number' ? row.weightValue : null,
        isDisabled: false,
        editedText: lang === defaultLanguage ? row.text : null
      }
    })
  }
}