import { useState } from "react";
import { Editor } from "@monaco-editor/react";

type Props = {
  editorTheme: 'vs-dark' | 'vs-light';
  editorValues: { html: string; properties: string; };
  setEditorValues: (values: { html: string; properties: string; }) => void;
}

const editorLanguageTabs = ["HTML", "Properties"] as const;

export const CustomDataOpsEditTabContent = ({ editorTheme, editorValues, setEditorValues }: Props) => {
  const [activeEditorLanguageTab, setActiveEditorLanguageTab] = useState<(typeof editorLanguageTabs[number])>('HTML');

  return (
    <div className="d-flex flex-column gap-lg w-100">
      <div className="d-flex justify-content-between">
        <div className="list-group list-group-horizontal">
          {
            editorLanguageTabs.map(tab =>
              <button
                key={tab}
                type="button"
                onClick={() => setActiveEditorLanguageTab(tab)}
                className={`list-group-item list-group-item-action py-1 ${activeEditorLanguageTab === tab ? 'active' : ''}`}>
                {tab}
              </button>
            )
          }
        </div>
      </div>
      {
        activeEditorLanguageTab === 'HTML' &&
        <Editor
          theme={editorTheme}
          defaultLanguage={'html'}
          value={editorValues.html}
          options={{ tabSize: 8, fontSize: 13 }}
          className={editorTheme === 'vs-light' ? 'border' : ''}
          onChange={(val) => setEditorValues({ ...editorValues, html: val || "" })}
        />
      }
      {
        activeEditorLanguageTab === 'Properties' &&
        <Editor
          theme={editorTheme}
          defaultLanguage={'json'}
          value={editorValues.properties}
          options={{ tabSize: 8, fontSize: 13 }}
          className={editorTheme === 'vs-light' ? 'border' : ''}
          onChange={(val) => setEditorValues({ ...editorValues, properties: val || "" })}
        />
      }
    </div>
  )
}