import { useState, type MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@progress/kendo-react-tooltip";

import { Icon } from "../../../shared/Icon/Icon";
import { formatDate } from "../../../shared/DateUtils/DateUtils";
import type { RootState } from "../../../../store/reducers/rootReducer";
import {
  updateCustomDataOpsData,
  updateCustomDataOpsSelectedItem,
  updateCustomDataOpsShowCreateEditModal,
  updateCustomDataOpsShowDeleteModal
} from "../../../../store/reducers/customDataOpsStateReducer/customDataOpsStateReducer";
import { fetchPatchJson } from "../../../../services/services";

export const CustomDataOpsListItemsTable = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customDataOpsData, searchValue, tagsFilter, customersFilter } = useSelector((state: RootState) => state.customDataOpsStateReducer);
  const [isPublishLoading, setIsPublishLoading] = useState(false);

  const onSortHandler = (field: string) => {
    console.log(field);
  }

  const sort = { field: 'name', dir: 'asc' };

  const SortingArrow = ({ field, text }: { field: string, text: string }) => {
    return (
      <div className={`d-flex align-items-center ${field === 'published' ? 'justify-content-center' : ''}`}>
        <span className='mr-0'>{text}</span>
        {
          sort.field === field ?
            sort.dir === 'asc' ?
              <div style={{ transform: 'rotate(180deg)' }}>
                <Icon type="chevron-mini-down-alt" />
              </div>
              :
              <div>
                <Icon type="chevron-mini-down-alt" />
              </div>
            : null
        }
      </div>
    )
  }

  const onEditItem = (e: MouseEvent<HTMLButtonElement>, id: string) => {
    e.stopPropagation();
    dispatch(updateCustomDataOpsShowCreateEditModal({ show: true, item: customDataOpsData?.find(el => el.id === id) || null }));
  }

  const onDeleteItem = (e: MouseEvent<HTMLButtonElement>, id: string) => {
    e.stopPropagation();
    dispatch(updateCustomDataOpsShowDeleteModal({ show: true, item: id }));
  }

  const onPublishItemToggle = (e: MouseEvent<HTMLButtonElement>, id: string) => {
    e.stopPropagation();
    setIsPublishLoading(true);
    const selectedItem = customDataOpsData?.find(item => item.id === id);
    if (selectedItem) {
      const body = { ...selectedItem, published: !selectedItem.published };
      fetchPatchJson(`su/customtools/${selectedItem.id}`, token, body)
        .then((res: TODO) => res.json())
        .then((data: TODO) => {
          setIsPublishLoading(false);
          if (data.error || data.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: data.error || data.message } });
          } else {
            if (customDataOpsData) {
              dispatch(updateCustomDataOpsData(customDataOpsData.map(item => item.id === selectedItem.id ? { ...item, ...data } : item)));
            }
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `The item has been ${data.published ? 'published' : 'unpublished'} successfully` } });
          }
        })
    }
  }

  return (
    <div style={{ flex: '1 1 auto' }} className="overflow-auto px-2">
      <table className="table table-hover custom-questions-table mb-0">
        <thead style={{ userSelect: 'none' }}>
          <tr>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <th onClick={() => onSortHandler('name')} className='cursor-pointer' scope="col">
              <SortingArrow field='name' text='Name' />
            </th>
            <th scope='col'>Customers</th>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <th onClick={() => onSortHandler('createdByName')} className='cursor-pointer' scope="col">
              <SortingArrow field='createdByName' text='Created by' />
            </th>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <th onClick={() => onSortHandler('createdUtc')} className='cursor-pointer' scope="col">
              <SortingArrow field='createdUtc' text='Date' />
            </th>
            <th scope="col">Tags</th>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <th onClick={() => onSortHandler('published')} className='cursor-pointer' scope="col">
              <SortingArrow field='published' text='Status' />
            </th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {
            customDataOpsData?.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.createdByName.toLowerCase().includes(searchValue.toLowerCase()))
              .filter((item) => tagsFilter.length === 0 || tagsFilter.some((tag) => item.tags?.includes(tag)))
              .filter((item) => customersFilter.length === 0 || customersFilter.some((customer) => item.customerAccess?.some((el) => el.name === customer.name)))
              .map((item) => (
                // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                <tr onClick={() => dispatch(updateCustomDataOpsSelectedItem(item.id))} className='cursor-pointer custom-data-ops-item' key={item.id}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className={`mr-2 bg-custom-questions-${item.iconColor ? item.iconColor : 'grey'}`} style={{ borderRadius: '0.375rem', padding: '0.125rem' }}>
                        <Icon type={item.icon ? item.icon : 'ic-carousel'} />
                      </div>
                      <span className='strong text-primary'>{item.name}</span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex gap-sm flex-wrap">
                      {
                        item.customerAccess?.map((customer) => (
                          <div key={customer.name} className='badge badge-light px-2 small font-weight-light'>
                            <span>{customer.name}</span>
                          </div>
                        ))}
                    </div>
                  </td>
                  <td>{item.createdByName}</td>
                  <td>{formatDate(item.createdUtc, "DATE_MED")}</td>
                  <td>
                    <div className="d-flex gap-sm flex-wrap">
                      {
                        item.tags?.map((tag: string) => (
                          <div key={tag} className={"badge badge-light px-2 small font-weight-light"}>
                            <span>{tag}</span>
                          </div>
                        )
                        )
                      }
                    </div>
                  </td>
                  <td className="text-center">
                    <span className={`badge px-2 small font-weight-light ${item.published ? 'badge-success-light' : 'badge-light'}`}>{item.published ? 'Published' : 'Draft'}</span>
                  </td>
                  <td>
                    <div className="custom-data-ops-item-actions gap-md">
                      <Tooltip openDelay={500} position='auto' anchorElement={'target'}>
                        <button
                          type='button'
                          title={item.published ? 'Unpublish' : 'Publish'}
                          className="btn btn-shadow px-2"
                          onClick={(e) => onPublishItemToggle(e, item.id)}>
                          {
                            isPublishLoading ?
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                              :
                              <i className="far fa-lg fa-external-link pe-none" />
                          }
                        </button>
                      </Tooltip>
                      <Tooltip openDelay={500} position='auto' anchorElement={'target'}>
                        <button onClick={(e) => onEditItem(e, item.id)} title="Edit" type="button" className="btn btn-shadow px-1">
                          <Icon type="edit" className="pe-none" />
                        </button>
                      </Tooltip>
                      <Tooltip openDelay={500} position='auto' anchorElement={'target'}>
                        <button onClick={(e) => onDeleteItem(e, item.id)} title="Delete" type="button" className="btn btn-shadow px-1">
                          <Icon type="delete" className="pe-none" />
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))
          }
        </tbody>
      </table>
    </div>
  )
}