import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { DropDownButton, Button } from '@progress/kendo-react-buttons';

import tabs from './tabsData.json';
import appMenuItems from './AnalysisAppMenuItems.json';
import { Icon } from '../../../../../../shared/Icon/Icon';
import FolderIcon from "../../../../../../../assets/img/library-folder.svg";
import { TabContent } from '../TabContent/TabContent';
import { Search } from '../../../shared/Search/Search';
import { SideBar } from '../../../../../../shared/SideBar/SideBar';
import DroppableColumn from '../../DroppableColumn/DroppableColumn';
import { Breadcrumb } from '../../../../../../shared/Breadcrumb/Breadcrumb';
import usePrevious from '../../../../../../shared/customHooks/usePrevious';
import DropdownMenu from '../../../../../../shared/DropdownMenu/DropdownMenu';
import { AnalysisModals } from '../../../shared/AnalysisModals/AnalysisModals';
import { InboxDrawer } from '../../../../../../shared/InboxDrawer/InboxDrawer';
import { AnalysisScriptModal } from '../AnalysisScriptModal/AnalysisScriptModal';
import { FullHeightSpinner } from '../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import ConfirmActionModal from '../../../../../../shared/ConfirmActionModal/ConfirmActionModal';
import { returnTableIcon } from '../../../../../../shared/helpers/project/returnTableIcon/returnTableIcon';
import EditProjectDocument from '../../../../../../Project/ProjectDetails/ProjectDocumentsTable/EditProjectDocument/EditProjectDocument';
import { ExportProjectDocumentModal } from '../../../../../../Project/ProjectDetails/ProjectDocumentsTable/ExportProjectDocumentModal/ExportProjectDocumentModal';
import { DropdownButton } from '../../../../../../shared/DropdownButton/DropdownButton';
import { InProgressOverlay } from '../../../../../../shared/InProgressOverlay/InProgressOverlay';
import returnDefineRangeData from '../../../shared/helpers/returnDefineRangeData/returnDefineRangeData';
import returnEnabledAnalysisTabs from '../../../shared/helpers/returnEnabledAnalysisTabs/returnEnabledAnalysisTabs';
import returnAutoSaveSessionBody from '../../../shared/helpers/returnAutoSaveSessionBody/returnAutoSaveSessionBody';
import returnOnQuestionCheckData from '../../../shared/helpers/returnOnQuestionCheckData/returnOnQuestionCheckData';
import returnOnDragEndDispatchType from '../../../shared/helpers/returnOnDragEndDispatchType/returnOnDragEndDispatchType';
import returnOnDragStartDispatchType from '../../../shared/helpers/returnOnDragStartDispatchType/returnOnDragStartDispatchType';
import { ImportCreatedQuestionsModal } from "./ImportCreatedQuestionsModal/ImportCreatedQuestionsModal";
import { useFetchAnalysisOptions } from '../../../../../../shared/customHooks/useFetchAnalysisOptions';
import { useFetchAnalysisStructure } from '../../../../../../shared/customHooks/useFetchAnalysisStructure';
import { useUpdateAnalysisOptionsData } from '../../../../../../shared/customHooks/useUpdateAnalysisOptionsData';
import { useLoadAnalysisWorkingSession } from '../../../../../../shared/customHooks/useLoadAnalysisWorkingSession';
import { returnMultiSelectionData } from '../../../shared/helpers/returnMultiSelectionData/returnMultiSelectionData';
import { useFetchAnalysisFunctionalities } from '../../../../../../shared/customHooks/useFetchAnalysisFunctionalities';
import { returnSessionDropdownItems } from '../../../shared/helpers/returnSessionDropdownItems/returnSessionDropdownItems';
import ReportModal from '../ReportModal/ReportModal';
import { fetchDelete as deleteCustomQuestion, fetchPost as updateSession, fetchPutResOrJson as updateUserSettings, fetchGetJson, rsAppUrl, fetchDelete, fetchPostJson } from '../../../../../../../services/services';

export const Analyze = ({ userData, user, token, ...props }) => {
  document.title = "Analysis – Walr"
  const dispatch = useDispatch();
  const params = useParams()
  const history = useHistory()
  const { state: xtState } = useSelector(theState => theState.stateReducer, shallowEqual);
  const { state: digState } = useSelector(theState => theState.digStateReducer, shallowEqual);
  const { state: chartState } = useSelector(theState => theState.chartStateReducer, shallowEqual);
  const { state: dataState } = useSelector(theState => theState.rawDataStateReducer, shallowEqual);
  const { state: qTableState } = useSelector(theState => theState.qTableStateReducer, shallowEqual);
  const { state: scriptState } = useSelector(theState => theState.scriptStateReducer, shallowEqual);
  const { state: toplineState } = useSelector(theState => theState.toplineStateReducer, shallowEqual);
  const analysisScriptingState = useSelector(theState => theState.analysisScriptingStateReducer, shallowEqual)
  const reportState = useSelector(theState => theState.reportStateReducer, shallowEqual);
  const recentData = useSelector(theState => theState.recentDataReducer, shallowEqual)
  const { data, selectedSession } = useSelector(theState => ({ data: theState.breadcrumbStateReducer.data, selectedSession: theState.breadcrumbStateReducer.selectedSession }), shallowEqual);
  const { theData, oldData } = useSelector(theState => theState.setInitialDataReducer, shallowEqual);
  const userSettings = useSelector((state) => state.userSettingsReducer, shallowEqual);

  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showActivitySidebar, setShowActivitySidebar] = useState(false);
  const [tabSelected, setTabSelected] = useState(0)
  const [questionsTabSelected, setQuestionsTabSelected] = useState(0)
  const [createQuestionData, setCreateQuestionData] = useState([])
  const [customItemSelected, setCustomItemSelected] = useState(null)
  const [editCustomQuestion, setEditCustomQuestion] = useState(false)
  const [deleteQuestionModal, setDeleteQuestionModal] = useState({
    question: null,
    show: false
  })
  const [openCreateQuestion, setOpenCreateQuestion] = useState({ show: false, type: null })
  const [expandedOptions, setExpandedOptions] = useState(false)
  const [didMount, setDidMount] = useState(true)
  const [didMountSession, setDidMountSession] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const [showSaveSessionModal, setShowSaveSessionModal] = useState({ show: false })
  const [showLoadSessionModal, setShowLoadSessionModal] = useState(false)
  const [showClearSessionModal, setShowClearSessionModal] = useState(false)
  const [showImportCreatedQuestionModal, setShowImportCreatedQuestionModal] = useState(false)
  const [showAddScriptModal, setShowAddScriptModal] = useState({ show: false, item: null })
  const [showDeleteScriptModal, setShowDeleteScriptModal] = useState({ show: false, id: null })
  const [showAppMenuModal, setShowAppMenuModal] = useState({ editDatasetModal: false, exportDatasetModal: false, deleteDatasetModal: false });
  const [showAppMenu, setShowAppMenu] = useState(false);
  const [isFinished, setIsFinished] = useState(false)
  const [isSessionLoaded, setIsSessionLoaded] = useState(false)
  const [optionsData, setOptionsData] = useState({
    allOptions: [],
    groups: [],
    data: [],
    dropdownValues: [],
    selectedGroup: null,
    didFetchAttributes: false,
    shouldUpdateOptions: false,
    didMount: false
  })
  const [defineRange, setDefineRange] = useState({ show: false, type: '' })
  const [rangeItems, setRangeItems] = useState([])
  const [checkedQuestions, setCheckedQuestions] = useState([])
  const [checkedReportQuestions, setCheckedReportQuestions] = useState([])
  const [didMountReport, setDidMountReport] = useState(true)
  const [importedCreatedQuestion, setImportedCreatedQuestion] = useState(null)
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false)
  const [showScriptModal, setShowScriptModal] = useState(false)
  const [loadingAnalysisScriptingResult, setLoadingAnalysisScriptingResult] = useState(false)
  const [selectedAnalysisScriptingItem, setSelectedAnalysisScriptingItem] = useState(null)
  const sessionDropdownItems = returnSessionDropdownItems(selectedSession)
  const projectId = params.name
  const datasetId = params.dataset
  const prevDidMount = usePrevious(optionsData.didMount)
  const analysisFunctionalities = useFetchAnalysisFunctionalities(projectId)

  const location = useLocation()
  const locationItems = location.pathname.split('/')
  const activeRouteTitle = locationItems[locationItems.length - 1]

  const createQuestionItems = [
    { name: 'Numeric question', icon: 'far fa-dot-circle', type: 'f' },
    { name: 'Categorical question', icon: "fas fa-th", type: 'm' },
    { name: 'Import Created Question', icon: "fas fa-cloud-upload-alt", type: 'i' },
  ]

  const newTabs = tabs
  newTabs[6].title = `Report (${reportState.columnData.length})`
  const activeTabs = returnEnabledAnalysisTabs(analysisFunctionalities, newTabs, user)
  const reorderCrosstab = (activeTabs.some(el => el.id === "Crosstab") && activeTabs.findIndex(tab => tab.id === "Crosstab")) === 1;

  useEffect(() => {
    if (didMountReport) {
      const checkedRowsFiltered = reportState.questions.filter((item) => item.selected).map(el => el.id)
      setCheckedReportQuestions(checkedRowsFiltered)
      setDidMountReport(false)
    }
  }, [reportState, didMountReport])

  useEffect(() => {
    // If the user hasn't opened the Homepage before opening Analysis, recentData will be empty
    // This useEffect calls backend for recentData.recentProjects if that's the case
    const recentProjectsIndex = appMenuItems.findIndex(el => el.title === "Recent projects")
    if (appMenuItems[recentProjectsIndex].subItems.length < 1) {
      if (recentData.projectsLoaded === false) {
        fetchGetJson("sta/statistics/recentlymodifiedprojects", token)
          .then(res => {
            if (res && !res.error) {
              dispatch({ type: "SET_RECENT_PROJECTS_DATA", payload: res })
              if (res.length > 0) {
                res.forEach(item => {
                  if (appMenuItems[recentProjectsIndex].subItems.length < 5) {
                    const updatedItem = {
                      title: item.name,
                      action: "recentProjectSubitem",
                      originalData: { ...item },
                      icon: <img src={FolderIcon} className="mr-1" style={{ transform: "translate(-3px,-3px)" }} alt="Folder icon" />,
                    }
                    appMenuItems[recentProjectsIndex].subItems.push(updatedItem)
                  }
                })
              } else {
                appMenuItems[recentProjectsIndex].subItems.push({ title: "No data found", action: "none" })
              }
            }
          })
      } else {
        recentData.recentProjects.forEach(item => {
          if (appMenuItems[recentProjectsIndex].subItems.length < 5) {
            const updatedItem = {
              title: item.name,
              action: "recentProjectSubitem",
              originalData: { ...item },
              icon: <img src={FolderIcon} className="mr-1" style={{ transform: "translate(-3px,-3px)" }} alt="Folder icon" />,
            }
            appMenuItems[recentProjectsIndex].subItems.push(updatedItem)
          }
        })
      }
    }
  }, [token, dispatch, recentData.projectsLoaded, recentData.recentProjects])

  useEffect(() => {
    // Same as above but for recentData.recentDocuments
    const recentActivityIndex = appMenuItems.findIndex(el => el.title === "Recent activity")
    if (appMenuItems[recentActivityIndex].subItems.length < 1) {
      if (recentData.documentsLoaded === false) {
        fetchGetJson("sta/statistics/recentlymodifiedprojectitems", token)
          .then(res => {
            if (res && !res.error) {
              dispatch({ type: "SET_RECENT_DOCUMENTS_DATA", payload: res })
              if (res.length > 0) {
                if (appMenuItems[recentActivityIndex].subItems.length < 5) {
                  res.forEach(item => {
                    const updatedItem = {
                      title: item.name,
                      action: "recentActivitySubitem",
                      originalData: { ...item },
                      icon: <i className={`${returnTableIcon(`${item.type}s`)} mr-2`} style={{ width: "18px" }} />,
                    }
                    appMenuItems[recentActivityIndex].subItems.push(updatedItem)
                  })
                }
              } else {
                appMenuItems[recentActivityIndex].subItems.push({ title: "No data found", action: "none" })
              }
            }
          })
      } else {
        recentData.recentDocuments.forEach(item => {
          if (appMenuItems[recentActivityIndex].subItems.length < 5) {
            const updatedItem = {
              title: item.name,
              action: "recentActivitySubitem",
              originalData: { ...item },
              icon: <i className={`${returnTableIcon(`${item.type}s`)} mr-2`} style={{ width: "18px" }} />,
            }
            appMenuItems[recentActivityIndex].subItems.push(updatedItem)
          }
        })
      }
    }
  }, [token, dispatch, recentData.documentsLoaded, recentData.recentDocuments])

  useFetchAnalysisStructure(
    didMount,
    (val) => setDidMount(val),
    (val) => setIsLoading(val),
    (val) => setDidMountSession(val),
    (val) => setCreateQuestionData(val))

  useFetchAnalysisOptions(user, optionsData, prevDidMount, activeTabs, tabSelected, (val) => setOptionsData(val), isSessionLoaded)

  useUpdateAnalysisOptionsData(user, tabSelected, optionsData, activeTabs, (val) => setIsFinished(val), (val) => setOptionsData(val), isSessionLoaded)

  useLoadAnalysisWorkingSession(
    activeTabs,
    optionsData,
    didMountSession,
    (val) => setOptionsData(val),
    (val) => setTabSelected(val),
    (val) => setDidMountSession(val),
    (val) => setIsSessionLoaded(val))

  const onAutoSaveHandler = (currentState) => {
    const body = returnAutoSaveSessionBody(tabSelected, currentState, toplineState, xtState, chartState, digState, dataState, qTableState, scriptState, reportState, showScriptModal, analysisScriptingState)
    updateSession(`workingsessions/${datasetId}`, token, body)
      .then(res => res)
    if (showScriptModal) {
      dispatch({ type: "UPDATE_INITIAL_SCRIPT_STATE", payload: currentState })
    }
  }

  const onApplyWeightSet = (value, currentState) => {
    const body = returnAutoSaveSessionBody(tabSelected, currentState, toplineState, xtState, chartState, digState, dataState, qTableState, scriptState, reportState)

    body.chartState.questionOptions.GSelectedWeight = value;
    body.chartState.newQuestionOptions.GSelectedWeight = value;

    body.digState.questionOptions.SelectedWeight = value;
    body.digState.newQuestionOptions.SelectedWeight = value;

    body.qTableState.questionOptions.QtSelectedWeight = value;
    body.qTableState.newQuestionOptions.QtSelectedWeight = value;

    body.toplineState.questionOptions.SelectedWeight = value;
    body.toplineState.newQuestionOptions.SelectedWeight = value;

    body.xtState.questionOptions.XtSelectedWeight = value;
    body.xtState.newQuestionOptions.XtSelectedWeight = value;

    onAutoSaveHandler(currentState)
  }

  const onDragStart = result => {
    const dispatchTypes = returnOnDragStartDispatchType(showReportModal ? 6 : tabSelected, activeTabs)
    dispatch({ type: dispatchTypes.onDragStartAction, payload: { result: result } })
  }

  const onDragEnd = (result) => {
    const items = theData.filter(el => el.selected === true)
    items.forEach(el => {
      el.selected = false
    })
    const dispatchTypes = returnOnDragEndDispatchType(showReportModal ? 6 : tabSelected, activeTabs)
    if (result.destination.droppableId === 'scndColumn' && xtState.firstColumn[result?.source?.index]?.type === 'group') {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Group questions cannot be added to the column section' } })
    } else {
      dispatch({ type: dispatchTypes.onDragEndAction, payload: { result: result, theData: theData, rangeItems: items } })
      dispatch({ type: dispatchTypes.displayTableAction, payload: { theData: theData } })
      setRangeItems([])
      setCheckedQuestions([])
    }
  }

  const openOptionsDialog = () => {
    setShowOptionsModal(true);
    setExpandedOptions(true)
  }

  const handleOptionsDialogClose = () => {
    setShowOptionsModal(false);
  }

  const handleSelect = (e) => {
    if (e.selected !== activeTabs.length) {
      if (e.selected === 6) {
        setTabSelected(8)
      } else {
        setTabSelected(e.selected)
      }
      setOptionsData({ ...optionsData, shouldUpdateOptions: true })
    } else {
      dispatch({ type: "UPDATE_TOGGLE_MINIMIZE" });

      const { compactView, ...oldUserSettings } = JSON.parse(JSON.stringify(userSettings));
      const body = {
        settings: { ...oldUserSettings, minimize: !userSettings.minimize }
      }
      updateUserSettings(
        `users/${user[`${rsAppUrl}/userid`]}/settings`,
        token,
        body
      ).then((res) => res)
    }
  }

  const createQuestionItemRender = (props) => {
    return (
      <div>
        <span><i className={`${props.item.icon} mr-1`} />{props.item.name}</span>
      </div>
    )
  }
  const onCheckCustomQuestion = (itemId) => {
    if (defineRange.show === true && defineRange.type === 'customQuestions') {
      setCustomItemSelected(null)
      const rangeData = returnDefineRangeData(rangeItems, theData, itemId)
      setRangeItems(rangeData.list)
      dispatch({ type: 'SELECT_QUES_RANGE', payload: rangeData.data })
    }
    else {
      const data = returnOnQuestionCheckData(theData, itemId)
      if (data.filter(el => el.selected === true).length === 1) {
        setCustomItemSelected(data.filter(el => el.selected === true)[0].id)
      }
      else if (data.filter(el => el.selected === true).length > 1) {
        setCustomItemSelected(data.filter(el => el.selected === true).map(el => el.id))
      } else {
        setCustomItemSelected(null)
      }
      dispatch({ type: 'SELECT_CUSTOM_QUES', payload: data })
    }
  }

  const onDeleteCustomQuestion = () => {
    if (customItemSelected !== null && rangeItems.show !== true) {
      if (Array.isArray(customItemSelected)) {
        setDeleteButtonDisabled(true);
        deleteCustomQuestion(`an/projects/${projectId}/analysis/${datasetId}/custom-questions/`, token, '', customItemSelected)
          .then(res => {
            if (res && res.status === 204) {
              setDeleteQuestionModal({ show: false, question: null })
              setDeleteButtonDisabled(false);
              setCustomItemSelected(null)
              setDidMount(true)
            }
          })
      } else {
        setDeleteButtonDisabled(true);
        deleteCustomQuestion(`an/projects/${projectId}/analysis/${datasetId}/custom-questions/${customItemSelected}`, token)
          .then(res => {
            if (res && res.status === 204) {
              setDeleteQuestionModal({ show: false, question: null })
              setDeleteButtonDisabled(false);
              setCustomItemSelected(null)
              setDidMount(true)
            }
          })
      }
    }
  }

  const onEditCustomQuestion = () => {
    if (customItemSelected !== null && rangeItems.show !== true) {
      setEditCustomQuestion(true)
      setOpenCreateQuestion({ show: true, type: theData.find(el => el.id === customItemSelected).type })
    }
  }

  const onChangeQuestionsTab = (e) => {
    setQuestionsTabSelected(e.selected)
    setDefineRange({ show: false, type: '' })
    setRangeItems([])
    setCheckedQuestions([])
  }

  ["keyup", "keydown"].forEach((event) => {
    window.addEventListener(event, (e) => {
      document.onselectstart = () => !(e.key === "Shift" && e.shiftKey)
    });
  });

  const onCheckQuestion = (itemId, shouldDefineRange) => {
    const updatedQuestionData = returnMultiSelectionData(theData, checkedQuestions, shouldDefineRange, itemId, (data) => setCheckedQuestions(data), 'selected')
    dispatch({ type: 'SELECT_QUES_RANGE', payload: updatedQuestionData })
  }

  const onCheckQuestionReportTab = (e, id, source, itemId, shouldDefineRange) => {
    const checkedReport = reportState.questions.filter((item) => item.selected).map(el => el.id)
    const updatedReportState = JSON.parse(JSON.stringify(reportState))
    const updatedReportQuestions = JSON.parse(JSON.stringify(updatedReportState.questions))
    const updatedQuestionData = returnMultiSelectionData(updatedReportQuestions, checkedReport, shouldDefineRange, itemId, (data) => setCheckedReportQuestions(data), 'selected')
    dispatch({ type: 'REPORT_UPDATE_QUESTIONS', payload: updatedQuestionData })
  }

  const onSaveActionHandler = (e, currentState) => {
    switch (e.item.action) {
      case "saveAs":
        setShowSaveSessionModal({ show: true, state: currentState })
        break;
      case "open":
        setShowLoadSessionModal(true);
        break;
      case "reset":
        setShowClearSessionModal(true)
        break;
      case "update":
        if (selectedSession.id) {
          const stateDecider = (tab) => {
            switch (tab) {
              case 0:
                return toplineState
              case 1:
                return xtState
              case 2:
                return chartState
              case 3:
                return digState
              case 4:
                return qTableState
              case 5:
                return dataState
              case 8:
                return { data: analysisScriptingState.data }
              default:
                break;
            }
          }
          const currentTabState = stateDecider(tabSelected)
          const body = returnAutoSaveSessionBody(tabSelected, currentTabState, toplineState, xtState, chartState, digState, dataState, qTableState, scriptState, reportState, false, analysisScriptingState)
          updateSession(`projects/${projectId}/sessions/${datasetId}/${selectedSession.id}/data`, token, body)
            .then(res => {
              if (res && res.status === 200) {
                if (res && !res.error) {
                  dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The selected session has been updated successfully.' } })
                }
              }
            })
          if (tabSelected === 7) {
            dispatch({ type: "UPDATE_INITIAL_SCRIPT_STATE", payload: currentState })
          }
        }
        break;
    }
  }

  const onSelectQuestion = (e, id) => {
    dispatch({ type: 'SET_REPORT_DISPLAY_TABLE', payload: id })
  }

  const handleCloseCreateQuestionModal = () => {
    setImportedCreatedQuestion(null)
    setOpenCreateQuestion(false)
    setEditCustomQuestion(false)
  }

  const importCreatedQuestionHandler = (question) => {
    setImportedCreatedQuestion(question)
    setOpenCreateQuestion({ show: true, type: question.type })
  }

  const appMenuItemClick = (item) => {
    const redirectToPage = (dataItem) => {
      if (dataItem.type === "dataset") {
        window.open(`/projects/${dataItem.projectId}/${dataItem.id}/analyze/${dataItem.type}s`, "_self")
      } else if (dataItem.type === "survey") {
        window.open(`/projects/${dataItem.projectId}/${dataItem.id}/survey-builder`, "_self")
      } else if (dataItem.type === "sample") {
        window.open(`/projects/${dataItem.projectId}/${dataItem.id}/panel`, "_self")
      } else if (dataItem.type === "workflow") {
        window.open(`/projects/${dataItem.projectId}/${dataItem.id}/workflow`, "_self")
      } else if (item.originalData.type === "pinboard") {
        window.open(`/projects/${item.originalData.projectId}/${item.originalData.id}/pinboards`, "_self")
      } else {
        window.open(`/projects/${dataItem.id}`, "_self");
      }
    }

    switch (item.action) {
      case "openXml":
        setShowScriptModal(true);
        break;
      case "goToProject":
        history.push({ pathname: `/projects/${projectId}` });
        break;
      case "editDataset":
        setShowAppMenuModal({ editDatasetModal: true, exportDatasetModal: false, deleteDatasetModal: false })
        break;
      case "compactDataset":
        dispatch({ type: "UPDATE_COMPACT_VIEW", payload: { compactType: "compactDataset", value: item.switchValue } })
        break;
      case "compactRows":
        dispatch({ type: "UPDATE_COMPACT_VIEW", payload: { compactType: "compactRows", value: item.switchValue } })
        break;
      case "compactColumns":
        dispatch({ type: "UPDATE_COMPACT_VIEW", payload: { compactType: "compactColumns", value: item.switchValue } })
        break;
      case "exportDataset":
        setShowAppMenuModal({ editDatasetModal: false, exportDatasetModal: true, deleteDatasetModal: false })
        break;
      case "recentProjectSubitem":
        history.push({ pathname: `/projects/${item.originalData.id}` });
        break;
      case "recentActivitySubitem":
        redirectToPage(item.originalData)
        break;
      case "goToHome":
        window.open("/", "_self")
        break;
      case "delete":
        setShowAppMenuModal({ editDatasetModal: false, exportDatasetModal: false, deleteDatasetModal: true })
        break;
      default:
        break;
    }

    if (item.type !== "switch") {
      setShowAppMenu(false)
    }
  }

  const confirmDeleteDataset = () => {
    fetchDelete(`/projects/${projectId}/datasets/${datasetId}`, token)
      .then((res) => {
        if (res.status === 202) {
          setShowAppMenuModal({ editDatasetModal: false, exportDatasetModal: false, deleteDatasetModal: false })
          window.open(`/projects/${projectId}`, "_self")
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The dataset has been deleted successfully' } })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Could not delete dataset" } })
        }
      })
  }

  const confirmEditDataset = (res) => {
    dispatch({ type: 'UPDATE_DOCUMENT_BREADCRUMB', payload: { id: res.id, name: res.name, documentDescription: res.description } });
    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Dataset has been updated successfully" } });
  }

  const showAnalysisScripting = (xml, id) => {
    setSelectedAnalysisScriptingItem(id);
    setLoadingAnalysisScriptingResult(true);

    const analysisScriptingUrl = params.analysisType === "surveys" ? `an/projects/${projectId}/analysis/surveys/${datasetId}/script` : `an/projects/${projectId}/analysis/${datasetId}/script`;

    fetchPostJson(analysisScriptingUrl, token, { adoc: xml })
      .then((res) => {
        setLoadingAnalysisScriptingResult(false)
        if (!res.error && !res.message) {
          dispatch({ type: 'UPDATE_ANALYSIS_SCRIPTING_RESULT', payload: res })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.message ? res.message : res.error } })
        }
      })
  }

  const onScriptingDropdownItemClick = (item, element) => {
    if (item.text === 'Edit') {
      setShowAddScriptModal({ show: true, item: element })
    } else if (item.text === 'Delete') {
      setShowDeleteScriptModal({ show: true, id: element.id })
    }
  }

  if (isFinished) {
    return (
      <section className="main analysis-container d-flex overflow-hidden flex-fill vh-100">
        <AnalysisModals
          activeTabs={activeTabs}
          tabSelected={tabSelected}
          openCreateQuestion={openCreateQuestion}
          createQuestionData={createQuestionData}
          editCustomQuestion={editCustomQuestion}
          customItemSelected={customItemSelected}
          showAddScriptModal={showAddScriptModal}
          deleteQuestionModal={deleteQuestionModal}
          deleteButtonDisabled={deleteButtonDisabled}
          showLoadSessionModal={showLoadSessionModal}
          showSaveSessionModal={showSaveSessionModal}
          showDeleteScriptModal={showDeleteScriptModal}
          showClearSessionModal={showClearSessionModal}
          importedCreatedQuestion={importedCreatedQuestion}
          selectedAnalysisScriptingItem={selectedAnalysisScriptingItem}
          onAutoSaveHandler={onAutoSaveHandler}
          setDidMount={(val) => setDidMount(val)}
          setTabSelected={(val) => setTabSelected(val)}
          showAnalysisScripting={showAnalysisScripting}
          setShowAddScriptModal={(val) => setShowAddScriptModal(val)}
          setDeleteQuestionModal={(val) => setDeleteQuestionModal(val)}
          onDeleteCustomQuestion={(val) => onDeleteCustomQuestion(val)}
          setShowLoadSessionModal={(val) => setShowLoadSessionModal(val)}
          setShowSaveSessionModal={(val) => setShowSaveSessionModal(val)}
          handleCloseCreateQuestionModal={handleCloseCreateQuestionModal}
          setShowClearSessionModal={(val) => setShowClearSessionModal(val)}
          setShowDeleteScriptModal={(val) => setShowDeleteScriptModal(val)}
        />
        {
          showImportCreatedQuestionModal &&
          <ImportCreatedQuestionsModal
            token={token}
            hideModal={() => setShowImportCreatedQuestionModal(false)}
            submitImportedCreatedQuestion={importCreatedQuestionHandler}>
          </ImportCreatedQuestionsModal>
        }
        {showAppMenuModal.editDatasetModal &&
          <EditProjectDocument
            item={{ projectId: projectId, id: datasetId, type: "datasets", projectName: data.documentName, description: data.documentDescription, tags: null }}
            itemAction={{ projectId: projectId, id: datasetId, type: "datasets", projectName: data.documentName, description: data.documentDescription, tags: null }}
            handleClose={() => setShowAppMenuModal({ editDatasetModal: false, exportDatasetModal: false, deleteDatasetModal: false })}
            onUpdateData={confirmEditDataset}
          />
        }
        {showAppMenuModal.exportDatasetModal &&
          <ExportProjectDocumentModal
            token={token}
            itemAction={{ projectId: projectId, id: datasetId, type: "datasets" }}
            onHide={() => setShowAppMenuModal({ editDatasetModal: false, exportDatasetModal: false, deleteDatasetModal: false })} />
        }
        {showAppMenuModal.deleteDatasetModal &&
          <ConfirmActionModal
            type='document'
            projectName={data.documentName}
            handleClose={() => setShowAppMenuModal({ editDatasetModal: false, exportDatasetModal: false, deleteDatasetModal: false })}
            deleteItem={confirmDeleteDataset}
          />
        }
        {
          showReportModal &&
          <ReportModal
            user={user}
            token={token}
            isLoading={isLoading}
            activeTabs={activeTabs}
            rangeItems={rangeItems}
            selectedTab={tabSelected}
            datasetId={params.dataset}
            datasetName={data.documentName}
            datasetType={params.analysisType}
            optionsData={optionsData}
            setOptionsData={setOptionsData}
            setTabSelected={setTabSelected}
            expandedOptions={expandedOptions}
            showOptionsModal={showOptionsModal}
            openOptionsDialog={openOptionsDialog}
            onAutoSaveHandler={onAutoSaveHandler}
            analysisFunctionalities={analysisFunctionalities}
            handleOptionsDialogClose={handleOptionsDialogClose}
            onSelectQuestion={onSelectQuestion}
            onCheckQuestionReportTab={onCheckQuestionReportTab}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onClose={() => setShowReportModal(false)}
          />
        }
        {
          showScriptModal &&
          <AnalysisScriptModal
            onClose={() => setShowScriptModal(false)}
            user={user}
            token={token}
            datasetId={params.dataset}
            datasetName={data.documentName}
            datasetType={params.analysisType}
            optionsData={optionsData}
            analysisFunctionalities={analysisFunctionalities}
            onAutoSaveHandler={onAutoSaveHandler}
          />
        }
        {showActivitySidebar &&
          <div className='sidebar-activity'>
            <SideBar width="400px" onHide={() => setShowActivitySidebar(false)}>
              <InboxDrawer />
            </SideBar>
          </div>
        }
        <div className="d-flex flex-column overflow-hidden flex-fill">
          <div className="analysis-header container-fluid">
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="nav-menu">
                  <DropdownMenu
                    menuButton={<span className='d-flex align-items-center'><Icon className='menu-walr mr-2' type='walr' /> <span className='menu-label'>Analysis & Reporting</span><Icon className='menu-chevron' type='chevron' /></span>}
                    items={appMenuItems}
                    onClickHandler={appMenuItemClick}
                    onOpenMenu={() => setShowAppMenu(prevState => !prevState)}
                    showMenu={showAppMenu}
                  />
                </div>
                {props.location.pathname !== "/" ?
                  <Breadcrumb
                    routePath={props.location.pathname}
                    activeRouteTitle={activeRouteTitle}
                  />
                  : null}
                <div className="nav-cta d-flex flex-row">
                  {/* <button onClick={() => setShowScriptModal(true)} className='btn more'>
                    <Icon className='nav-icon' type="more-horizontal" />
                  </button> */}
                  <DropdownButton
                    text={"Sessions"}
                    hideChevron={true}
                    textField="actionName"
                    popupSettings={{ popupAlign: { horizontal: "right", vertical: "top" }, popupAnchor: { horizontal: "right", vertical: "bottom" } }}
                    parentClass="analysis-sessions"
                    className="btn sessions ml-2"
                    items={sessionDropdownItems}
                    onItemClick={(e) => onSaveActionHandler(e)}
                  >
                  </DropdownButton>
                  <button type='button' onClick={() => setShowReportModal(true)} disabled={reportState.columnData.length > 0 ? false : true} className='btn report ml-2'>
                    <span>{newTabs[6].title}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <div className="d-flex no-gutters bg-white overflow-hidden flex-fill">
              <ul className="analysis-nav nav d-flex flex-column h-100 pt-3">
                <React.Fragment>
                  {
                    activeTabs.filter(el => el.id !== 'Report' && el.id !== 'Script').map((el, id) => (
                      <li onClick={() => handleSelect({ selected: id })} key={id} className={`analysis-nav-item mb-1 ${reorderCrosstab && el.id === "Crosstab" ? "analysis-crosstab-reorder" : null}`}>
                        <span className={`analysis-nav-link ${tabSelected === id ? 'active' : ''}`}>
                          <Icon className='header-nav-icon' type={el.icon} />
                        </span>
                        <span className='analysis-nav-tooltip'>{el.title}</span>
                      </li>
                    ))
                  }
                  <div className='sidebar-collapse'>
                    <li className="analysis-nav-item mb-1" onClick={() => setShowActivitySidebar(prevState => !prevState)}>
                      <span className="analysis-nav-link">
                        <Icon className="header-nav-icon" type="nav-activity-ar" />
                      </span>
                      <span className='analysis-nav-tooltip'>Show activity</span>
                    </li>
                    <li className="analysis-nav-item" onClick={() => handleSelect({ selected: activeTabs.length })}>
                      <span className="analysis-nav-link">
                        <Icon className='header-nav-icon' type={userSettings.minimize ? "nav-collapse" : "nav-expand"} />
                      </span>
                      <span className='analysis-nav-tooltip'>{userSettings.minimize ? "Expand sidebar" : "Collapse sidebar"}</span>
                    </li>
                  </div>
                </React.Fragment>
              </ul>
              <div className={`${userSettings.minimize ? "hide-sidebar" : "show-sidebar"} d-flex flex-column bg-white analyze-sidebar border-left`}>
                <div className="d-flex flex-wrap flex-column bg-white border-bottom">
                  <div className="d-flex justify-content-between pt-2 px-2">
                    <span className='nav-title'>{activeTabs[tabSelected].title}</span>
                    {
                      tabSelected === 8 &&
                      <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
                        <button type='button' onClick={() => setShowAddScriptModal({ show: true, item: null })} title="Add new" className='btn btn-transparent btn-icon py-2 px-1'>
                          <Icon className='pe-none' type='add' />
                        </button>
                      </Tooltip>
                    }
                  </div>
                  <Search analysisScriptingSearch={tabSelected === 8} oldData={oldData} dispatch={dispatch} minimized={userSettings.minimize} />
                </div>
                {questionsTabSelected !== 0 &&
                  <div className={`d-flex overflow-hidden flex-wrap analysis-actions created-question justify-content-between ${userSettings.minimize ? "" : "p-1"}`}>
                    <DropDownButton
                      text="Create"
                      icon="fas fa fa-caret-down"
                      className={`analyze-actions-button ${userSettings.minimize ? "minimized-dropdown" : ""}`}
                      buttonClass={`btn btn-outline-analyze px-2 ${userSettings.minimize ? "flex-fill" : ""} d-flex flex-row-reverse`}
                      items={createQuestionItems}
                      disabled={!analysisFunctionalities.analysisActions.createQuestions}
                      itemRender={createQuestionItemRender}
                      onItemClick={(props) => props.item.type !== "i" ? setOpenCreateQuestion({ show: true, type: props.item.type }) : setShowImportCreatedQuestionModal(true)}
                    />
                    <div className='d-flex'>
                      <Button
                        className={`btn btn-outline-analyze d-flex px-2 ${userSettings.minimize ? "minimized-button" : ""}`}
                        // icon="fas fa fa-edit"
                        disabled={!!(!analysisFunctionalities.analysisActions.createQuestions || !customItemSelected || Array.isArray(customItemSelected))}
                        onClick={onEditCustomQuestion}>Edit</Button>
                      <Button
                        className={`btn btn-outline-analyze d-flex px-2 ${userSettings.minimize ? "minimized-button" : ""}`}
                        // icon="fas fa fa-eraser"
                        disabled={!!(!analysisFunctionalities.analysisActions.createQuestions || !customItemSelected)}
                        onClick={() =>
                          setDeleteQuestionModal({
                            show: true,
                            // question: theData.find(el => el.id === customItemSelected).title 
                            question: Array.isArray(customItemSelected) ? "multiple questions" : theData.find(el => el.id === customItemSelected).title
                          })}>Delete</Button>
                    </div>
                  </div>
                }
                {tabSelected === 6 ?
                  theData && theData.length > 0 && !isLoading ?
                    <div className="d-flex flex-column overflow-hidden report-list crosstab h-100 w-100">
                      <DroppableColumn report={true} onSelectQuestion={onSelectQuestion} rangeItems={rangeItems} theData={reportState} onCheck={onCheckQuestionReportTab} />
                    </div>
                    : theData === null ?
                      <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                        <span className="text-muted h6">No questions were found</span>
                      </div>
                      :
                      <InProgressOverlay
                        type="fullscreen"
                        theme="primary" />
                  :
                  tabSelected === 8 ?
                    <div className="d-flex flex-column flex-grow-1 gap-md overflow-auto p-2">
                      {
                        analysisScriptingState?.data?.filter(el => el.name.toLowerCase().includes(analysisScriptingState.searchValue.toLowerCase()) || el.description.toLowerCase().includes(analysisScriptingState.searchValue.toLowerCase()))?.length > 0 ?
                          analysisScriptingState.data.filter(el => el.name.toLowerCase().includes(analysisScriptingState.searchValue.toLowerCase()) || el.description.toLowerCase().includes(analysisScriptingState.searchValue.toLowerCase())).map((el, id) => (
                            <div onClick={() => showAnalysisScripting(el.xml, el.id)} key={id} className={`border border-secondary ${selectedAnalysisScriptingItem === el.id ? 'bg-light' : ''} rounded py-2 px-3 cursor-pointer`}>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center gap-md">
                                  <Icon type={'survey-xml'} className="question-icon bg-analyze" />
                                  <div className="d-flex flex-column">
                                    <strong title={el.name} style={{ width: '150px' }} className='text-truncate'>{el.name}</strong>
                                    <p title={el.description} style={{ width: '150px' }} className="m-0 text-truncate">{el.description}</p>
                                  </div>
                                </div>
                                <DropdownButton
                                  hideChevron={true}
                                  className='btn btn-transparent btn-icon px-1'
                                  text={<Icon type="more" />}
                                  textField='text'
                                  onItemClick={(e) => onScriptingDropdownItemClick(e.item, el)}
                                  items={[{ text: 'Edit' }, { text: 'Delete' }]}
                                />
                              </div>
                            </div>
                          ))
                          :
                          <div className="d-flex flex-column align-items-center gap-md mt-5">
                            <p className="text-muted mb-0">No data found</p>
                            <button type='button' className="btn btn-outline-light text-muted" onClick={() => setShowAddScriptModal({ show: true, item: null })}>
                              <i className="far fa-plus mr-2" />
                              Add
                            </button>
                          </div>
                      }
                    </div>
                    :
                    theData && theData.length > 0 && !isLoading ?
                      <TabStrip selected={questionsTabSelected} onSelect={(e) => onChangeQuestionsTab(e)} tabPosition="bottom" className="questions-tabs" rangeItems={rangeItems}>
                        <TabStripTab
                          contentClassName="created-questions-tab"
                          title={params.analysisType === 'surveys' ? 'Survey questions' : (userSettings.minimize ? <i title="Dataset questions" className="fas fa-database" /> : "Dataset questions")}>
                          <DroppableColumn questions={true} rangeItems={rangeItems} theData={theData} onCheck={onCheckQuestion} />
                        </TabStripTab>
                        {
                          params.analysisType !== 'surveys' &&
                          <TabStripTab title={userSettings.minimize ? <i title="Created questions" className="fas fa-plus-circle" /> : "Created questions"}>
                            <DroppableColumn customQuestions={true} theData={theData} onCheck={onCheckCustomQuestion} rangeItems={rangeItems} />
                          </TabStripTab>
                        }
                      </TabStrip>
                      : theData === null ?
                        <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                          <span className="text-muted h6">No questions were found</span>
                        </div>
                        :
                        <InProgressOverlay
                          type="fullscreen"
                          theme="primary" />
                }
              </div>
              <div className={`${userSettings.minimize ? "hidden-nav" : "visible-nav"} d-flex flex-column`}>
                {
                  isFinished &&
                  <TabContent
                    user={user}
                    token={token}
                    tabType={activeTabs[tabSelected].title}
                    userData={userData}
                    activeTabs={activeTabs}
                    rangeItems={rangeItems}
                    selectedTab={tabSelected}
                    optionsData={optionsData}
                    datasetId={params.dataset}
                    datasetName={data.documentName}
                    datasetType={params.analysisType}
                    expandedOptions={expandedOptions}
                    showOptionsModal={showOptionsModal}
                    analysisFunctionalities={analysisFunctionalities}
                    selectedAnalysisScriptingItem={selectedAnalysisScriptingItem}
                    loadingAnalysisScriptingResult={loadingAnalysisScriptingResult}
                    onAutoSaveHandler={onAutoSaveHandler}
                    openOptionsDialog={openOptionsDialog}
                    setTabSelected={(val) => setTabSelected(val)}
                    setOptionsData={(data) => setOptionsData(data)}
                    handleOptionsDialogClose={handleOptionsDialogClose}
                    onApplyWeightSet={onApplyWeightSet}
                  />
                }

              </div>
            </div>
          </DragDropContext >
        </div >
      </section >
    )
  }
  return (
    <div className="d-flex flex-column vh-100 w-100 flex-grow-1 justify-content-center align-items-center">
      <div className='analysis-header w-100' />
      <div className="d-flex flex-grow-1 justify-content-center align-items-center h-100">
        <FullHeightSpinner />
      </div>
    </div>
  )
}