import React from 'react';
import { useDispatch } from 'react-redux';

import { Avatar } from '../../../Avatar/Avatar';
import { formatDate } from '../../../../shared/DateUtils/DateUtils';
import handleTableRowClick from '../handleTableRowClick/handleTableRowClick';
import fileManagerDownloadHandler from '../../fileManagerDownloadHandler/fileManagerDownloadHandler';
import { ReadReceiptsDetailsComponent } from '../../../../Project/ProjectDetails/ProjectDocumentsTable/ReadReceiptsDetailsComponent/ReadReceiptsDetailsComponent';
import { fetchGetBlob, fetchGetJson } from '../../../../../services/services';

export const ColumnValue = ({ data, shareDetails, dataPropertyKeys, returnIcon, setStatus, bulletValueAxis, history, setDatasetBreadCrumb, setProjectBreadCrumb, token, isLastEntry, ...props }) => {
  const today = new Date(); // Date needed for Details in Read Receipts
  const yesterday = new Date(); // Date needed for Details in Read Receipts
  today.setHours(0, 0, 0, 0);
  yesterday.setHours(0, 0, 0, 0);
  yesterday.setDate(today.getDate() - 1);
  const dispatch = useDispatch();

  const onRowClick = (item) => {
    if (item.type !== "workflows" && item.type !== "pinboards" && item.type !== "surveys" && item.type !== 'audiences') {
      const itemType = item.type.includes('datasets') ? 'datasets' : item.type
      fetchGetJson(`projects/${item.projectId}/${itemType}/${item.id}`, token) // Needed for Read Receipts
    }
    if (item.type.includes('datasets')) {
      if (shareDetails?.sharedWithDetails?.shareFunctionalities) {
        const analysisTypes = shareDetails.sharedWithDetails.shareFunctionalities.analysisTypes
        const enabledTypes = Object.keys(analysisTypes).filter(type => analysisTypes[type] === true)
        if (enabledTypes.length > 0) {
          setDatasetBreadCrumb(item)
          handleTableRowClick(item, history)
        }
      } else {
        setDatasetBreadCrumb(item)
        handleTableRowClick(item, history)
      }
    } else if (item.type === "surveys" || item.type === "samples" || item.type === "workflows" || item.type === "pinboards" || item.type === "audiences") {
      if (item.type === "pinboards") {
        dispatch({ type: "RESET_PINBOARD_DATA" })
      }
      setDatasetBreadCrumb(item)
      handleTableRowClick(item, history)
    } else if (item.type === "files") {
      fetchGetBlob(`projects/${item.projectId}/files/${item.id}/download`, token)
        .then(res => {
          if (res) {
            fileManagerDownloadHandler(res, { displayName: item.name })
          }
        })
    } else {
      setProjectBreadCrumb(item)
      handleTableRowClick(item, history)
    }
  }

  if (data === 'name/description') {
    if (dataPropertyKeys.includes('name') && dataPropertyKeys.includes('description')) {
      return props.dataItem.name || props.dataItem.description ?
        <td onClick={() => onRowClick(props.dataItem)} style={{ "width": "35%" }}>
          <div className="d-flex align-items-center gap-md">
            <Avatar
              rounded="small"
              class="avatar-sm m-0 text-primary"
              icon={returnIcon(props.dataItem.type, props.dataItem.extension)}
              color={"secondary"}
            />
            <div className="mx-2">
              <div className='d-flex flex-column gap-xs'>
                <p className="strong m-0"> {props.dataItem.name}</p>
                {/* {
                  props.dataItem.description &&
                  <span className='text-muted'>{props.dataItem.description}</span>
                } */}
              </div>
              {
                props.dataItem.readReceipts &&
                <ReadReceiptsDetailsComponent
                  readReceipts={props.dataItem.readReceipts}
                  dateToday={today}
                  dateYesterday={yesterday}
                  isLastEntry={isLastEntry}
                />
              }
            </div>
          </div>
        </td>
        : null
    } return null
  } if (data === 'createdDate' || data === 'createdUtc') {
    if ((dataPropertyKeys.includes('createdDate') || dataPropertyKeys.includes('createdDate')) && dataPropertyKeys.includes('createdByName')) {
      return props.dataItem.createdUtc ?
        <td onClick={() => onRowClick(props.dataItem)} style={{ "width": "30%" }}>
          <div className="d-flex flex-column">
            <p className="m-0 small">{formatDate(props.dataItem.createdUtc, "DATETIME_MED")}</p>
            <p className='m-0 small'>Created by <span className="strong">{props.dataItem.createdByName}</span></p>
          </div>
        </td>
        : null
    } return null
  } if (data === 'status') {
    if (dataPropertyKeys.includes('status')) {
      return props.dataItem.status ?
        <td className="cell" onClick={() => onRowClick(props.dataItem)}>
          <div className="d-flex">
            <span className={`${setStatus(props.dataItem.status)} badge badge-pill`}>{props.dataItem.status}</span>
          </div>
        </td>
        : null
    } return null
  } if (data === 'interviews') {
    if (dataPropertyKeys.includes('interviews')) {
      return props.dataItem.interviews >= 0 ?
        <td onClick={() => onRowClick(props.dataItem)}>
          <div className="d-flex align-items-center gap-md">
            <i className="fas fa-users" />
            <p className='m-0'>{props.dataItem.interviews}</p>
          </div>
        </td >
        : <td onClick={() => onRowClick(props.dataItem)} />
    } return null
  } if (data === 'live') {
    if (dataPropertyKeys.includes('live')) {
      return props.dataItem.type === 'surveys' ?
        <td onClick={() => onRowClick(props.dataItem)}>
          <div className="d-flex">
            <p className="h5">
              <span className={'badge ' + (props.dataItem.live ? " badge-success" : " badge-secondary")}>
                {props.dataItem.live ? "Live" : "Draft"}</span>
            </p>
          </div>
        </td>
        : <td />
    } return null
  } if (data === 'tags') {
    if (dataPropertyKeys.includes('tags')) {
      return props.dataItem.tags ?
        <td onClick={() => onRowClick(props.dataItem)}>
          <div className="d-flex flex-wrap gap-sm">
            {
              props.dataItem.tags.map((tag) => (
                <span
                  key={tag.id}
                  style={{ lineHeight: 'unset' }}
                  className="badge badge-light font-weight-normal px-2">{tag.name}
                </span>
              ))
            }
          </div>
        </td>
        : <td />
    } return null
  }
  return null
}
