import { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import type { CustomDataOpsData } from '../../../../interfaces/customDataOpsInterface/customDataOpsInterface';
import { SurveyFilterBuilder } from '../../../Survey/SurveyTabContent/SurveyDesignTabContent/SurveyFilterBuilder/SurveyFilterBuilder';

type Props = {
  selectedItem: CustomDataOpsData;
}

const iFrameOrigin = "https://saweupfpublicstaging.blob.core.windows.net" as const;

export const CustomDataOpsPreviewTabContent = ({ selectedItem }: Props) => {
  const [dataFromIframe, setDataFromIframe] = useState({ xml: "", state: {} });
  const [dataForIframe] = useState({ inputStructure: {}, filter: "", props: {} });
  const [showFilterBuilder, setShowFilterBuilder] = useState({ display: false, filterData: {}, primaryIndex: null, manualFilterExpression: null });
  const [iframeUrl, setIframeUrl] = useState("");
  const [didMount, setDidMount] = useState(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!didMount) {
      setDidMount(true);
      setIframeUrl(selectedItem.htmlUrl ? `${selectedItem.htmlUrl}?nocache=${new Date().getTime()}` : "");
    }
  }, [didMount, selectedItem.htmlUrl]);

  const getXmlResultFromIframe = () => {
    iframeRef?.current?.contentWindow?.postMessage("generateXmlAndProps", iFrameOrigin);
  };

  const getDataFromIframe = useCallback((event: { origin: string, data: { type: string, result: typeof dataFromIframe } }) => {
    if (event.origin === iFrameOrigin) {
      if (event.data.type === "returnXmlAndProps") {
        setDataFromIframe(event.data.result);
      }
    }
  }, []);

  const sendDataToIframeOnLoad = useCallback(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.contentWindow?.postMessage({ type: "dataForIframe", data: dataForIframe }, iFrameOrigin);
    }
  }, [dataForIframe]);

  const sendFilterExpressionToIframe = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      setShowFilterBuilder({ display: false, filterData: [], primaryIndex: null, manualFilterExpression: null });
      iframe.contentWindow?.postMessage({ type: "filterExpression", data: "filterExpression" }, iFrameOrigin);
    }
  }

  const openFilterBuilderFromIframe = useCallback((event: { origin: string, data: string }) => {
    if (event.origin === iFrameOrigin) {
      if (event.data === "openFilterBuilder") {
        setShowFilterBuilder({
          display: true,
          filterData: {
            questions: [
              {
                "qno": "Weight.a",
                "subqIndex": 0,
                "type": "f",
                "questionId": "43ab14d2-171c-49ec-a03f-17aabc04aaeb",
                "isQuota": false,
                "rows": [
                  {
                    "texts": {
                      "en": "Weight"
                    },
                    "position": 1
                  }
                ],
                "texts": {
                  "en": "Weight"
                }
              }
            ]
          },
          primaryIndex: null,
          manualFilterExpression: null
        });
      }
    }
  }, [])

  useEffect(() => {
    const iframe = iframeRef.current;
    iframe?.addEventListener("load", sendDataToIframeOnLoad);
    window.addEventListener("message", getDataFromIframe);
    window.addEventListener("message", openFilterBuilderFromIframe)

    return () => {
      iframe?.removeEventListener("load", sendDataToIframeOnLoad);
      window.removeEventListener("message", getDataFromIframe);
      window.removeEventListener("message", openFilterBuilderFromIframe);
    };
  }, [getDataFromIframe, sendDataToIframeOnLoad, openFilterBuilderFromIframe]);

  return (
    <Fragment>
      {
        showFilterBuilder.display &&
        <SurveyFilterBuilder
          token={'token'}
          type={'infoText'}
          option={'filter'}
          surveyId={'surveyId'}
          combineFilterData={[]}
          selectedTab={undefined}
          onSaveTextFilter={undefined}
          filterData={showFilterBuilder.filterData}
          manualFilterExpression={showFilterBuilder.manualFilterExpression}
          onSaveFilter={() => sendFilterExpressionToIframe()}
          handleClose={() => setShowFilterBuilder({ display: false, filterData: [], primaryIndex: null, manualFilterExpression: null })}
        />
      }
      <div className="d-flex flex-column w-100">
        <div className="d-flex align-items-center gap-lg mb-3">
          <button
            type="button"
            className="btn btn-primary"
            onClick={getXmlResultFromIframe}>
            Get XML
          </button>
        </div>
        <p>XML received from the iframe below: <strong>{dataFromIframe.xml}</strong></p>
        <p>State received from the iframe below: <strong>{JSON.stringify(dataFromIframe.state)}</strong></p>
        <iframe
          ref={iframeRef}
          className="border h-100"
          title="Custom data ops item"
          src={iframeUrl}
        />
      </div>
    </Fragment>
  )
}